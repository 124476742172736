import { useStaticQuery, graphql } from "gatsby"

export const useBlogPage = () => {
  const { allNodeBlogPage } = useStaticQuery(
    graphql`
      query BlogPage {
        allNodeBlogPage {
          edges {
            node {
              field_titlebgimglefttext_text_
              field_titlebgimglefttext_title_
              relationships {
                field_titlebgimglefttext_img_ {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeBlogPage.edges[0].node
}
