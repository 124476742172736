import React from "react"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import BlockTitleBgImgLeftText from "../components/BlockTitleBgImgLeftText/BlockTitleBgImgLeftText"
import BlockMultiColumns from "../components/BlockMultiColumns/BlockMultiColumns"
import Footer from "../components/Footer/Footer"

import { useBlogData, useBlogPage } from "../hooks/use-blogPage"
import { useBlogPosts } from "../hooks/use-blogPosts"

const Blog = () => {
  const blogData = useBlogPage()
  const blogPosts = useBlogPosts()

  console.log(blogPosts)

  const titleBgImgLeftTextData = {
    title: blogData.field_titlebgimglefttext_title_,
    text: blogData.field_titlebgimglefttext_text_,
    img: blogData.relationships.field_titlebgimglefttext_img,
  }

  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <BlockTitleBgImgLeftText data={titleBgImgLeftTextData} />
        <BlockMultiColumns posts={blogPosts} />
        <Footer />
      </div>
    </div>
  )
}

export default Blog
