import React from "react"
import { Link } from "gatsby"
import styles from "./blockMultiColumns.module.css"

const BlockMultiColumnsItem = ({ data }) => {
  console.log(data)
  const {
    title,
    summary,
    // img: {
    //   uri: { url: image },
    // },
    path: { alias: slugPath },
  } = data
  return (
    <div className={styles.blockMultiColumns__item}>
      <div className={styles.blockMultiColumns__item__img}>
        {/* <Link to={slugPath}>
          <img src={`https://drupal.smartempo.com${image}`} alt={title} />
        </Link> */}
      </div>
      <div className={styles.blockMultiColumns__item__titleText}>
        <h3>{title}</h3>
        <p>{summary}</p>
        <Link to={slugPath}>
          <div className={styles.blockMultiColumns__item__titleText__button}>
            More view
          </div>
        </Link>
      </div>
    </div>
  )
}

const BlockMultiColumns = ({ bgColor, posts }) => {
  return (
    <section className={styles.blockMultiColumns}>
      {posts.map((post, idx) => {
        const postData = {
          title: post.node.field_blog_post_title_,
          summary: post.node.field_blog_post_summary_,
          img: post.node.relationships.field_blog_post_imgpreview,
          path: post.node.path,
        }
        const bid = `b-${idx}`
        return <BlockMultiColumnsItem key={bid} data={postData} />
      })}
    </section>
  )
}
export default BlockMultiColumns
