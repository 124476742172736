import React from "react"
import styles from "./blockTitleBgImgLeftText.module.css"

const BlockTitleBgImgLeftText = ({ bgColor, data }) => {
  const {
    title,
    text,
    // img: {
    //   uri: { url: image },
    // },
  } = data
  return (
    <section className={styles.blockTitleBgImgLeftText}>
      <div className={styles.blockTitleBgImgLeftText__title}>
        <h1>{title}</h1>
        <p>{text}</p>
      </div>
      {/* <div className={styles.blockTitleBgImgLeftText__img}>
        <img src={`https://drupal.smartempo.com${image}`} alt={title} />
      </div> */}
      {/* <div className={styles.blockTitleBgImgLeftText__text}>
        <p>{text}</p>
      </div> */}
    </section>
  )
}
export default BlockTitleBgImgLeftText
