import { useStaticQuery, graphql } from "gatsby"

export const useBlogPosts = () => {
  const { allNodeBlogPost } = useStaticQuery(
    graphql`
      query BlogPosts {
        allNodeBlogPost {
          edges {
            node {
              id
              path {
                alias
              }
              field_blog_post_title_
              field_blog_post_body_ {
                processed
              }
              field_blog_post_summary_
              relationships {
                field_blog_post_imgpreview_ {
                  uri {
                    url
                  }
                }
                field_blog_post_img_ {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeBlogPost.edges
}
